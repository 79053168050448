

<template>
<div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                  <b-form-group label="Filter by Role" class="mb-2" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        id="radio-group-2"
                        v-model="defaultRole"
                        :aria-describedby="ariaDescribedby"
                        name="radio-default-status"
                    >
                        <b-form-radio  value="all">All</b-form-radio>
                        <b-form-radio  value="admin">Admin</b-form-radio>
                        <b-form-radio  value="worker">Worker</b-form-radio>
                        <b-form-radio  value="buyer">Buyer</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-sm-12 col-md-6">
                          <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                              <span class="mr-1">Display</span>
                              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                          <div class="form-inline float-sm-right">
                            <div id="tickets-table_filter" class="dataTables_filter mr-sm-2 text-md-right">
                              <label class="d-inline-flex mb-0 align-items-center">
                                <span class="mr-2 d-none d-sm-inline">Search:</span>
                                <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                              </label>
                            </div>
                             <b-dropdown right variant="light" size="sm">
                              <template slot="button-content">
                                   <i class="mdi mdi-filter-variant"></i>
                              </template>
                              <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}</b-dropdown-item-button>
                              <b-dropdown-item-button ><i class="mdi mdi-delete-forever-outline"></i> Delete</b-dropdown-item-button>
                            </b-dropdown>
                          </div>
                        </div>
                    </div>
                    <div class="table-responsive mb-0">
                        <b-table table-class="table table-centered w-100" thead-tr-class="bg-light" :items="usersData" :fields="columns" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                            <template v-slot:cell(check)="data">
                              <div class="custom-control custom-checkbox text-center">
                                <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkuser${data.item.id}`" />
                                <label class="custom-control-label" :for="`contacusercheck${data.item.id}`">{{data.item.id}}</label>
                              </div>
                            </template>

                            <template v-slot:cell(image)="data">
                              <img @click="navigateTo(data.item.id)" :src="$store.state.domain+'/'+data.item.image" alt="" class="rounded" style="cursor: pointer;" height="48" />
                            </template>
                            <template v-slot:cell(name)="data">
                              <h5 class="m-0 d-inline-block align-middle">
                                <a href="#" @click.prevent="navigateTo(data.item.id)" v-if="data.item.role == 'buyer'" class="text-dark">{{ data.item.first_name }} {{ data.item.last_name }}</a>
                                <a href="#" @click.prevent="navigateTo(data.item.id)" v-if="data.item.role == 'worker'" class="text-dark">{{ data.item.username }}</a>
                              </h5>
                            </template>
                            <template v-slot:cell(email)="data">
                              <h5 class="m-0 d-inline-block align-middle">
                                <a href="#" @click.prevent="navigateTo(data.item.id)" class="text-dark">{{ data.item.email }}</a>
                              </h5>
                            </template>
                            <template v-slot:cell(role)="data">
                              <h5 class="m-0 d-inline-block align-middle">
                                <a href="#" @click.prevent="navigateTo(data.item.id)" class="text-dark">{{ data.item.role }}</a>
                              </h5>
                            </template>
                            <template v-slot:cell(status)="data">
                              <span class="badge badge-soft-success" :class="{'badge-soft-danger': data.item.status === 'deactivated'}">{{ data.item.status }}</span>
                            </template>
                            <template v-slot:cell(is_verified)="data">
                              <span class="d-block text-center">
                                <span class="badge badge-soft-success" :class="{'badge-soft-danger': data.item.is_verified}">{{ data.item.is_verified? '✔️' : '❌' }}</span>
                              </span>
                            </template>
                            <template v-slot:cell(action)="data">
                                <ul class="list-inline table-action m-0">
                                  <li v-if="data.item.id != activeUser.id" class="list-inline-item">
                                    <a @click="deleteItem(data.item.id)" href="javascript:void(0);" class="action-icon text-danger">
                                        <i class="mdi mdi-delete"></i></a>
                                  </li>
                                </ul>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
  data() {
    return {
      isLoading:true,
      defaultRole:"all",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
              key: "image",
              sortable: true
          },
          {
              key: "name",
              sortable: true
          },
          {
              key: "email",
              sortable: true
          },
          {
              key: "role",
              sortable: true
          },
          {
              key: "status",
              label: "Status",
              sortable: true
          },
          {
              key: "is_verified",
              label: "Verified",
              sortable: true
          },
          "action"
      ],
      selected: [],
      isSelectAll: false,
    }
  },
  watch: {
    "$route.query":"refreshQueryRole"
  },
  computed: {
    activeUser(){
        return this.$store.state.AppActiveUser
    },
    usersData() {
      return this.defaultRole == "all"
             ? this.$store.state.userList.users
             : this.$store.state.userList.users.filter((u) => (u.role == this.defaultRole))
    },
    rows() {
        return this.usersData.length;
    },
  },
  methods: {
    refreshQueryRole(){
        if(this.$route.query.role){
          this.defaultRole = this.$route.query.role
        }
    },
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: `/account`});
      }else{
        this.$router.push({path: `/users/${id}`});
      }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.usersData.map(u => u.id );
      }else{
        this.selected = []
      }
    },
    deleteItem(itemId){
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete a user",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/users/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('userList/DELETE_USER', itemId)
            }
          })
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.refreshQueryRole()
    this.$store.dispatch("userList/fetchUsers")
    .then((response)=>{
      this.isLoading = false
    })
  }
}

</script>

<style lang="scss">

</style>
